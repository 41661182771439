import { BaseFrameState } from '@utils/hooks/useBaseFrame';
import { Button, Flex, LabelText } from '@components';
import { useBFContext } from '@context';
import { MIX_MATERIAL_SWATCHES_OPTIONS, FRAME_COLORS } from '@constants';
import styles from './Material.module.scss';

type MaterialProps = {
	callback: (material: string, frameColor: string) => void;
	vtoState?: BaseFrameState;
	selectedMaterial: string;
};

const Material = ({ callback, vtoState, selectedMaterial }: MaterialProps) => {
	const { frameColor } = useBFContext();

	const materialMappings = MIX_MATERIAL_SWATCHES_OPTIONS.reduce((acc, option) => {
		if (option.metal) {
			acc[option.acetate] = ['Acetate', option.metal];
			acc[option.metal] = ['Acetate', option.metal];
		} else {
			acc[option.acetate] = ['Acetate', FRAME_COLORS.MIX_CRYSTAL_SILVER];
		}

		return acc;
	}, {});

	const frameColorOption = vtoState ? vtoState.frame : frameColor;
	const materials = materialMappings[frameColorOption];

	return (
		<>
			<LabelText>Material</LabelText>
			<Flex gap={3}>
				{materials.map((material, index) => {
					const label =
						material === 'Acetate'
							? 'Acetate'
							: MIX_MATERIAL_SWATCHES_OPTIONS.find(option => option.metal === material)?.label;
					return (
						<Button
							key={index}
							label={label}
							size='small'
							color='white'
							onClick={() => callback(material, frameColor)}
							removeEffects
							extraClasses={selectedMaterial === material ? styles.selectButton : styles.materialButton}
							dataTags={{ 'data-base-frame-material': label }}
						/>
					);
				})}
			</Flex>
		</>
	);
};

export default Material;
